import React from "react";
import { Container, Text, Button } from "@atoms";
import { HubspotForm } from "@molecules";

const NewsletterDonate = ({ newsletter, donate }) => {
  return (
    <section className="my-10 sm:my-20">
      <Container
        variant="sm"
        padding
        className="flex w-full flex-wrap items-start gap-6 sm:grid sm:grid-cols-2"
      >
        <div>
          <HubspotForm {...newsletter} />
        </div>
        <div className="flex flex-col gap-4 rounded-3xl bg-white p-6 shadow">
          <Text variant="h4" className="text-center text-purple">
            {donate.heading}
          </Text>
          <Text variant="body" className="text-center">
            {donate.descriptor}
          </Text>
          <ul className="my-auto grid grid-cols-2 gap-3 sm:grid-cols-2">
            <li>
              <Button fullWidth color="white" outline to="/donate/?amount=1000">
                $1000
              </Button>
            </li>
            <li>
              <Button fullWidth color="white" outline to="/donate/?amount=500">
                $500
              </Button>
            </li>
            <li>
              <Button fullWidth color="white" outline to="/donate/?amount=250">
                $250
              </Button>
            </li>
            <li>
              <Button fullWidth color="white" outline to="/donate/?amount=100">
                $100
              </Button>
            </li>
            <li>
              <Button fullWidth color="white" outline to="/donate/?amount=25">
                $25
              </Button>
            </li>
            <li>
              <Button fullWidth color="white" outline to="/donate/">
                Other
              </Button>
            </li>
          </ul>
          <div className="mt-auto flex items-center justify-center">
            <Button color="purple" to="/donate">
              Donate
            </Button>
          </div>
        </div>
        {/* <Donorbox {...donate} /> */}
      </Container>
    </section>
  );
};

NewsletterDonate.defaultProps = {};

export default NewsletterDonate;
